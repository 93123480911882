
















































































































































.auth-container {
  width: 100%;
  max-width: 615px;
  margin: 0 auto;
  margin-top: 15vh;
  .custom-control-label {
    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 0px;
    }
  }
  .input-group-text {
    padding: 5px 10px;
  }
  .login-header{
    background: #284373;
  }
}
